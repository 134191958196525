import Base from './base';
import Header from '../header';
import Footer from '../footer';
import Progressive from '../progressive';

export default function Layout({ children }) {

    return (
        <Base>
            <Header />
            { children }
            <Footer />
            <Progressive />
        </Base>
    );
}
