import Link from "next/link";
import { t } from "i18next";
import { useSession, signOut } from "next-auth/react";
import isMobile from "../lib/isMobile";
import { useAuth } from "../contexts/AuthUserContext";

export default function ModalMenu() {
    const { data: session } = useSession();
    const { signOut: firebaseSignOut } = useAuth();

    const closeModal = () => {
        window.$('#modalMenu').modal('hide');

        const modalBackdropArr = document.querySelectorAll('.modal-backdrop.fade.show');
        
        modalBackdropArr.forEach(modalBackdrop => {
            document.body.removeChild(modalBackdrop);
        });

        document.body.style.removeProperty('padding-right');
        document.body.classList.remove('modal-open');
    };

    const clickAppDownload = (e) => {
        if (!isMobile(window.navigator.userAgent)) {
            e.preventDefault();
            document.getElementById('qrCodeDiv')?.scrollIntoView({ behavior: 'instant' });
        }
    }

    return (
        <div className="modal modal-nav fade" id="modalMenu" tabIndex="-1" role="dialog" aria-labelledby="modalMenu" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-body z-bigger-10">
                        <div className="container">
                            <div className="close-wrap">
                                <div className="row">
                                    <div className="col-auto col-lg align-self-center text-lg-right pl-4">
                                        {
                                        session?.user ?
                                        <button onClick={() => {closeModal(); signOut({ redirect: false }); firebaseSignOut();}} className="btn btn-black-border btn-small btn-radius parallax-elements js-tilt">{t('common.logOut')}</button> :
                                        <Link href="/login" className="btn btn-black-border btn-small btn-radius parallax-elements js-tilt" onClick={() => closeModal()}>{t('common.logIn')}</Link>
                                        }
                                    </div>
                                    <div className="col-auto align-self-center">
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-center pt-4 pt-xl-5">
                                <div className="col-lg-5 col-xl-4 pl-lg-5">
                                    <div className="section left-line">
                                        <div className="section">
                                            <div className="anime-fade">
                                                <a className="link-nav d-block sub-icon mb-2" data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="true" aria-controls="collapseExample">
                                                    <div className="link-nav-circle"></div>{t('modalMenu.subjectAbout')}
                                                </a>
                                            </div>
                                            <div className="collapse show" id="collapseExample">
                                                <div className="card card-body">
                                                    <div className="anime-fade">
                                                        <Link className="link-nav-sub d-block mb-1 " href="/companyinformation" onClick={() => closeModal()}>
                                                            {t('modalMenu.companyInformation')}
                                                        </Link>
                                                    </div>
                                                    <div className="anime-fade">
                                                        <Link className="link-nav-sub d-block mb-1 " href="/team" onClick={() => closeModal()}>
                                                            {t('modalMenu.team')}
                                                        </Link>
                                                    </div>
                                                    {/*
                                                    <div className="anime-fade">
                                                        <Link className="link-nav-sub d-block " href="/partners" onClick={() => closeModal()}>
                                                            {t('modalMenu.partners')}
                                                        </Link>
                                                    </div>
                                                    */}
                                                </div>
                                            </div>
                                            <div className="anime-fade">
                                                <a className="link-nav d-block sub-icon mb-2" data-toggle="collapse" href="#collapseExample0" role="button" aria-expanded="true" aria-controls="collapseExample1">
                                                    <div className="link-nav-circle"></div>{t('modalMenu.subjectServices')}
                                                </a>
                                            </div>
                                            <div className="collapse show" id="collapseExample0">
                                                <div className="card card-body">
                                                    <div className="anime-fade">
                                                        <Link className="link-nav-sub d-block " href="/services" onClick={() => closeModal()}>
                                                            {t('modalMenu.services')}
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="anime-fade">
                                                <a className="link-nav d-block sub-icon mb-2" data-toggle="collapse" href="#collapseExample1" role="button" aria-expanded="true" aria-controls="collapseExample1">
                                                    <div className="link-nav-circle"></div>{t('modalMenu.subjectAnalysis')}
                                                </a>
                                            </div>
                                            <div className="collapse show" id="collapseExample1">
                                                <div className="card card-body">
                                                    <div className="anime-fade">
                                                        <Link className="link-nav-sub d-block " href="/testanalysis" onClick={() => closeModal()}>
                                                            {t('modalMenu.testAnalysis')}
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5 col-xl-4 pl-lg-5">
                                    <div className="section left-line">
                                        <div className="section">
                                            <div className="anime-fade">
                                                <a className="link-nav d-block sub-icon mb-2" data-toggle="collapse" href="#collapseExample2" role="button" aria-expanded="true" aria-controls="collapseExample2">
                                                    <div className="link-nav-circle"></div>{t('modalMenu.subjectCs')}
                                                </a>
                                            </div>
                                            <div className="collapse show" id="collapseExample2">
                                                <div className="card card-body">
                                                    <div className="anime-fade">
                                                        <Link className="link-nav-sub d-block mb-1 " href="/news" onClick={() => closeModal()}>
                                                            {t('modalMenu.news')}
                                                        </Link>
                                                    </div>
                                                    <div className="anime-fade">
                                                        <Link className="link-nav-sub d-block mb-1 " href="/blog" onClick={() => closeModal()}>
                                                            {t('modalMenu.blog')}
                                                        </Link>
                                                    </div>
                                                    <div className="anime-fade">
                                                        <Link className="link-nav-sub d-block " href="/contact" onClick={() => closeModal()}>
                                                            {t('modalMenu.contact')}
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="anime-fade">
                                                <a className="link-nav d-block sub-icon mb-2" data-toggle="collapse" href="#collapseExample3" role="button" aria-expanded="true" aria-controls="collapseExample3">
                                                    <div className="link-nav-circle"></div>{t('modalMenu.subjectAppDownload')}
                                                </a>
                                            </div>
                                            <div className="collapse show" id="collapseExample3">
                                                <div className="card card-body">
                                                    <div className="anime-fade">
                                                        <Link className="link-nav-sub d-block mb-1 " href={process.env.NEXT_PUBLIC_APP_DEEP_LINK} onClick={(e) => {closeModal(); clickAppDownload(e);}}>
                                                            {t('modalMenu.appDownload')}
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                            {/*
                                            <div className="anime-fade">
                                                <a className="link-nav d-block sub-icon mb-2" data-toggle="collapse" href="#collapseExample4" role="button" aria-expanded="true" aria-controls="collapseExample4">
                                                    <div className="link-nav-circle"></div>Profile
                                                </a>
                                            </div>
                                            <div className="collapse show" id="collapseExample4">
                                                <div className="card card-body">
                                                    <div className="anime-fade">
                                                        <Link className="link-nav-sub d-block mb-1 " href="#" onClick={(e) => e.preventDefault()}>
                                                            계정
                                                        </Link>
                                                    </div>
                                                    <div className="anime-fade">
                                                        <Link className="link-nav-sub d-block mb-1 " href="#" onClick={(e) => e.preventDefault()}>
                                                            회원탈퇴
                                                        </Link>
                                                    </div>
                                                    <div className="anime-fade">
                                                        <Link className="link-nav-sub d-block " href="#" onClick={(e) => e.preventDefault()}>
                                                            로그아웃
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                            */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
