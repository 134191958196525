import { signOut, useSession } from 'next-auth/react';
import Head from 'next/head';
import { useAuth } from '../../contexts/AuthUserContext';
import { useEffect } from 'react';

export default function Base({ children }) {
    const { onAuthStateChanged, getBearerToken, signOut: firebaseSignOut } = useAuth();
    const { data: session, update } = useSession();

    useEffect(() => {
        const authStateChanged = (user) => {
            if (session?.user) {
                if (user?.email !== session.user?.email) {
                    signOut({ redirect: false });
                    firebaseSignOut();
                    return;
                }

                if (getBearerToken() !== session.user?.bearerToken) {
                    update({ ...session.user, bearerToken: getBearerToken() });
                }
            }
        };

        const unsubscribe = onAuthStateChanged(authStateChanged);

        return unsubscribe;
    }, [session, onAuthStateChanged, getBearerToken, update, firebaseSignOut]);

    return (
        <>
            <Head>
                <meta charSet="utf-8" />
                <title>이대로-edaero</title>
                <meta name="description"  content="수능점수분석, 모의고사점수분석, 입시준비, 입시정보, 입시컨설팅, 정시, 수시" />
                <meta name="author" content="edaero.kr" />
                <meta name="keywords"  content="수능점수분석, 모의고사점수분석, 입시준비, 입시정보, 입시컨설팅, 정시, 수시" />
                <meta property="og:title" content="edaero-이대학으로" />
                <meta property="og:type" content="" />
                <meta property="og:url" content="https://edareo.kr" />
                <meta property="og:image" content="./edaero.jpg" />
                <meta property="og:image:width" content="470" />
                <meta property="og:image:height" content="246" />
                <meta property="og:site_name" content="edaero-이대학으로" />
                <meta property="og:description" content="수능점수분석, 모의고사점수분석, 입시준비, 입시정보, 입시컨설팅, 정시, 수시" />
                <meta name="twitter:card" content="edaero-이대학으로" />
                <meta name="twitter:site" content="https://edareo.kr" />
                <meta name="twitter:domain" content="edaero.kr" />
                <meta name="twitter:title" content="edaero-이대학으로" />
                <meta name="twitter:description" content="수능점수분석, 모의고사점수분석, 입시준비, 입시정보, 입시컨설팅, 정시, 수시" />
                <meta name="twitter:image" content="./edaero.jpg" />

                {/* Mobile Specific Metas 
                ================================================== */}
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
                <meta name="theme-color" content="#007AFF"/>
                <meta name="msapplication-navbutton-color" content="#007AFF"/>
                <meta name="apple-mobile-web-app-status-bar-style" content="#007AFF"/>

                {/* Favicons
                ================================================== */}
                <link rel="icon" type="image/png" href="/img/favicon.png" />
                <link rel="apple-touch-icon" href="/img/apple-touch-icon.png" />
                <link rel="apple-touch-icon" sizes="72x72" href="/img/apple-touch-icon-72x72.png" />
                <link rel="apple-touch-icon" sizes="114x114" href="/img/apple-touch-icon-114x114.png" />
            </Head>

            { children }
        </>
    );
}
