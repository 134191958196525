import Image from "next/image";
import logo from "/public/img/logo.svg";
import Link from "next/link";
import ModalMenu from "./modal-menu";
import { t } from "i18next";
import { useSession, signOut } from "next-auth/react";
import isMobile from "../lib/isMobile";
import { useAuth } from "../contexts/AuthUserContext";

export default function Header() {
    const { data: session } = useSession();
    const { signOut: firebaseSignOut } = useAuth();

    const clickAppDownload = (e) => {
        if (!isMobile(window.navigator.userAgent)) {
            e.preventDefault();

            document.getElementById('qrCodeDiv')?.scrollIntoView({ behavior: 'instant' });
        }
    }

    return (
        <>
            {/* Navigation
	        ================================================== */}
            <div className="navigation-wrap cbp-af-header">
                <div className="container-fluid px-xl-5 nav-load-anime">
                    <div className="row">
                        <div className="col-auto align-self-center">	
                            <Link href="/" className=" d-inline">
                                <div className="logo">	
                                    <Image src={logo} alt="" />
                                </div>	
                            </Link>
                        </div>
                        <div className="col align-self-center">
                            <div className="row">
                                <div className="col align-self-center text-right d-none d-md-inline">	
                                </div>
                                <div className="col-auto align-self-center px-xl-4 d-none d-md-inline">	
                                    <Link href="/testanalysis" className="link-normal weight-600">{t('common.testAnalysis')}</Link>
                                    <Link href={process.env.NEXT_PUBLIC_APP_DEEP_LINK} className="link-normal weight-600 ml-3" onClick={clickAppDownload}>{t('common.appDownload')}</Link>
                                </div>
                                <div className="col col-md-auto align-self-center text-right">
                                    {
                                    session?.user ?
                                    <button onClick={() => {signOut({ redirect: false }); firebaseSignOut();}} className="btn btn-black-border btn-small btn-radius parallax-elements js-tilt">{t('common.logOut')}</button> :
                                    <Link href="/login" className="btn btn-black-border btn-small btn-radius parallax-elements js-tilt">{t('common.logIn')}</Link>
                                    }
                                </div>
                                <div className="col-auto align-self-center pl-xl-4 text-right">	
                                    <div className="hamb-icon" data-toggle="modal" data-target="#modalMenu"></div>	
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalMenu />
        </>
    );
}
